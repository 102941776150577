function stringToDate(timeString) {
    const [time, ampm] = timeString.split(' ');
    const [hours, minutes] = time.split(':');
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    if (ampm === 'PM') {
        date.setHours(date.getHours() + 12);
    }
    return date;
}

async function getSunsetTime() {
    const apiKey = 'e82f37a98eb74343bef180343231902'; // Replace this with your API key
    const city = 'Ojai';
    const state = 'CA';
    const country = 'USA';
    const url = `https://api.weatherapi.com/v1/forecast.json?key=${apiKey}&q=${city},${state},${country}&days=1`;

    try {
        const response = await fetch(url);
        const data = await response.json();
        const sunsetTime = data.forecast.forecastday[0].astro.sunset;
        console.log(sunsetTime)
        return stringToDate(sunsetTime);
    } catch (error) {
        console.error(error);
    }
}

function dateToString(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = 'AM';
    if (hours > 12) {
        hours -= 12;
        ampm = 'PM';
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    return `${hours}:${minutes} ${ampm}`;
}

function calculateTimeLeft(pinkMomentTime) {
    var now = new Date();
    var diff = pinkMomentTime - now;
    var seconds = diff / 1000;
    var minutes = seconds / 60;
    var hours = minutes / 60;
    return {
        minutes: Math.floor(minutes),
        hours: Math.floor(hours),
        seconds: Math.floor(seconds),
    }
}

export const helperFunctions = { stringToDate, getSunsetTime, dateToString, calculateTimeLeft }