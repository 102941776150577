import { useEffect, useState } from 'react';
import './App.css';
import pinkmomentimage from './assets/pinkMoment.jpeg'

import AboutText from './components/AboutText';
import Clock from './components/clock';
import Timer from './components/timer';
import ShareButton from './components/shareButton';

import { helperFunctions } from './helperfunctions';

function App() {
  const [clockTime, setclockTime] = useState("eshtablishing a link")
  const [countDown, setCountDown] = useState("^<>")

  function updateClock() {
    helperFunctions.getSunsetTime().then(sunsetTime => {
        console.log(sunsetTime)
        var MS_PER_MINUTE = 60000;
        var pinkMomentTime = new Date(sunsetTime - 14 * MS_PER_MINUTE);

        pinkMomentTime.setHours(sunsetTime.getHours())
        pinkMomentTime.setMinutes(sunsetTime.getMinutes() - 14)

        let hours = pinkMomentTime.getHours();
        let minutes = pinkMomentTime.getMinutes();

        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;

        setclockTime(helperFunctions.dateToString(pinkMomentTime)) ;
        var timeleft = helperFunctions.calculateTimeLeft(pinkMomentTime);
        setCountDown(`${timeleft.hours} hours and ${timeleft.minutes-timeleft.hours * 60} minutes`) 
        setInterval(() => {
            var timeleft = helperFunctions.calculateTimeLeft(pinkMomentTime);
            setCountDown(`${timeleft.hours} hours and ${timeleft.minutes-timeleft.hours * 60} minutes`) 
          }, 60000)

    });
}

useEffect(() => {
    updateClock()
  },[]);

  return (
    <div className="body">
      <p className="time-left-wrapper">if visible...<br/>
Today's Pink Moment will begin at approximately</p >
      <Clock clockTime={clockTime}/>
      {/* <Timer countDown={countDown}/> */}
      <ShareButton/>
      <AboutText/>
    </div>
  );
}

export default App;
