
import '../App.css';

function Clock({clockTime}) {


    return (
        <div class="clock">{clockTime}</div>
  );
}

export default Clock;
