
import '../App.css';
import { useState } from 'react';

function ShareButton() {
    const [message, setMessage] = useState('');
  
    const handleShareClick = () => {
      if (navigator.share) {
        navigator.share({
          title: document.title,
          url: window.location.href,
        })
          .then(() => setMessage('Shared successfully'))
          .catch((error) => setMessage(`Error sharing: ${error.message}`));
      } else if (navigator.clipboard) {
        navigator.clipboard.writeText(window.location.href)
          .then(() => setMessage('Link copied to clipboard'))
          .catch((error) => setMessage(`Error copying to clipboard: ${error.message}`));
      } else {
        setMessage('Sharing not supported in this browser');
      }
    };
  
    return (
      <div >
        <button className='share-button' onClick={handleShareClick}>
          Share
        </button>
        {message && (
          <div>
            {message}
          </div>
        )}
      </div>
    );
  }
  
  export default ShareButton;
  
